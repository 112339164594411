<template>
  <VueFinalModal
    class="flex flex-col items-center justify-center h-full !z-[99999999] modal_wrapper"
    click-to-close
    content-class="absolute inset-0 "
    display-directive="if"
    @update:model-value="(val) => emit('update:modelValue', false)"
  >
    <span
      class="inline cursor-pointer z-10 absolute top-3 md:top-7 right-3 md:right-7 text-white w-[24px] h-[24px]"
      @click="close"
    >
      <CloseIcon class="inline cursor-pointer text-white w-[24px] h-[24px]" />
    </span>
    <div
      class="absolute inset-0 flex items-center h-full overflow-auto justify-senter"
      @click.self="() => emit('update:modelValue', false)"
    >
      <div
        class="flex flex-col mx-auto my-12 rounded"
        :class="modalContainerClass"
      >
        <div v-if="title" class="w-full px-4 py-6 text-left border-b">
          <h4
            class="text-xl font-semibold text-gray-600 uppercase"
            v-text="title"
          />
        </div>
        <div
          :class="modalBodyClass"
          class="w-full text-base rounded-lg shadow-lg"
        >
          <slot />
        </div>
        <div
          v-if="hasSlot('footer')"
          class="w-full p-4 border-t sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  close: {
    type: Function,
    default: null,
  },
  modalBodyClass: {
    type: [Array, String],
    default: "",
  },
  modalContainerClass: {
    type: [Array, String],
    default: "max-w-xl px-4 py-2 max-w-xl",
  },
});
const emit = defineEmits(["update:modelValue"]);

const slots = useSlots();

function hasSlot(name = "default") {
  return !!slots[name] || !!slots.default[name];
}
</script>

<style lang="postcss">
.modal_wrapper .vfm__overlay {
  @apply bg-gray-500 bg-opacity-60;
}

.modal_wrapper .vfm__content {
  @apply items-center justify-center flex;
}
</style>
